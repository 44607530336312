(function footerContainer () {
    const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
    const compName = "footer-container";
    const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
    const Log = logFactory(`${compName}.js`);

     if (!isCompExists(compName, Log)) {
        return;
    }

    $(async () => {
        const stickyButton = document.querySelector('.sticky-button');
        const footerContainer = document.querySelector('.footer__container');

        if (stickyButton && footerContainer) {
            const stickyButtonHeight = stickyButton.offsetHeight;
            const footerContainerHeight = Number(window.getComputedStyle(footerContainer).getPropertyValue('padding-bottom').replace('px', ''));

            footerContainer.style.paddingBottom = `${stickyButtonHeight + footerContainerHeight}px`;
        }
    });
 })()