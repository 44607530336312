

(function faqProduit () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "faq-produit";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    const inbentaApi = GlobalSite.checkDependency('GlobalSite.inbentaApi');

    $(() => {
      $(async () => {
        const inbentaAuth = await inbentaApi.auth();
        const urlsProduct = inbentaAuth && (await inbentaApi.getUrlsProduct());
        const currentPath = window.location.pathname;
        const productContents = urlsProduct?.results[0]?.value?.urls.find((url) => url.url === currentPath)?.contents.replaceAll(';', ',');
        const urlsProductContents = inbentaAuth && (await inbentaApi.getUrlsProductContents(productContents));
        const faqList = document.querySelector('#faq-produit-list');

        if (faqList) {    
            const skeletonItems = faqList.querySelectorAll('.faq__list-item--loader');
            const answers = urlsProductContents;

            if (skeletonItems && skeletonItems.length > 0) {
              skeletonItems.forEach(item => item.remove());
            }

                if (answers && answers?.results?.length > 0) {
                answers.results.map((answer) => {
                    const htmlAnswer = `
                        <li class="faq__list-item">
                            <details
                                data-tracking-type="click"
                                data-source="faq"
                                data-tracking-value="${answer.tracking.clickCode}"
                                class="accordion"
                            >
                                <summary class="accordion__title" aria-label="${answer.title}">
                                    <h3>${answer.title}</h3>
                                    <span class="accordion__title--icon i-chevron-down" />
                                </summary>
                                <div class="accordion__text">
                                    ${answer.attributes.ANSWER_TEXT}
                                </div>
                            </details>
                        </li>
                    `

                        faqList.insertAdjacentHTML('afterbegin', htmlAnswer);
                })

                const faqAccordionList = document.querySelectorAll('#faq-produit-list .faq__list-item .accordion');
                const inbentaTracking = await inbentaApi.isTracking();

                                if (faqAccordionList && faqAccordionList.length > 0 && inbentaTracking) {
                    faqAccordionList.forEach((accordion) => {
                        accordion.addEventListener('click', () => {
                            const isOpen = accordion.hasAttribute('open');

                            if (!isOpen) {
                                const source = accordion.dataset.source;
                                const trackingType = accordion.dataset.trackingType;
                                const trackingValue = accordion.dataset.trackingValue;

                                                                inbentaApi.sendTrackingEvents({ type: trackingType, data: { code: trackingValue }}, source);
                                Log.log({ trackingType, trackingValue, source });
                            }
                        });
                    })
                }
            } else {
                const errorBanner = faqList.querySelector('.faq__list-item--banner-message');
                errorBanner.classList.toggle('hide');
            }
        }
      });
    })

    })()