

(function footerMain () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "footer-main";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    const footerItems = document.querySelectorAll('.footer-item');

    const showSubmenu = (footerItem) => {
      const submenu = footerItem.querySelector('.footer-submenu');
      const arrowIcon = footerItem.querySelector('.arrow');
      if (submenu && arrowIcon) {
        submenu.style.transform = 'translateY(-0.5rem)';
        submenu.style.display = 'flex';
        arrowIcon.style.transform = 'rotate(180deg)';
        setTimeout(() => {
          submenu.style.transform = 'translateY(0)';
          submenu.style.opacity = 1;
        }, 200);
      }
    };

    const hideSubmenu = (footerItem) => {
      const submenu = footerItem.querySelector('.footer-submenu');
      const arrowIcon = footerItem.querySelector('.arrow');
      if (submenu && arrowIcon) {
        submenu.style.display = 'none';
        submenu.style.opacity = 0;
        arrowIcon.style.transform = '';
      }
    };

    const handleFooterItemEvent = (event) => {
      const footerItem = event.currentTarget;
      const submenu = footerItem.querySelector('.footer-submenu');

      footerItems.forEach((otherFooterItem) => {
        if (otherFooterItem !== footerItem) {
          hideSubmenu(otherFooterItem);
        }
      });

      if (submenu && ((submenu.style.display === 'none') || (submenu.style.display === ''))) {
        showSubmenu(footerItem);
      } else {
        hideSubmenu(footerItem);
      }
    };

    const handleFooter = () => {
      footerItems.forEach((footerItem) => {
        footerItem.addEventListener('click', handleFooterItemEvent);
      });
    }

    const onResize = () => {
      handleFooter();
    }

        const onDomContentLoaded = () => {
      handleFooter();
    }

    window.addEventListener('resize', onResize);

    $(() => {
      onDomContentLoaded();
    })

    })()