(function table () {
  const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
  const compName = "table";
  const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
  const Log = logFactory(`${compName}.js`);

   if (!isCompExists(compName, Log)) {
     return;
   }

   $(() => {
     let isMobile = window.matchMedia("(max-width: 768px)");
     let isMounted = false;

     const splide = new Splide('.rowgroup_carousel', {
       perPage: 1,
       perMove: 1,
       easing: 'ease-out',
       classes: {
         prev: 'i-arrow-left-short',
         next: 'i-arrow-right-short'
       },
       i18n: {
         prev: "Colonne précédente",
         next: "Colonne suivante",
         slideX: "Aller à la colonne %s",
         carousel: "tableau",
         slide: "colonne",
         select: "Sélectionner une colonne à afficher",
         slideLabel: "%s sur %s"
       }
     });

     splide.on('mounted', () => {
       const arrowsContainer = document.querySelector('.splide__arrows');
       const paginationContainer = document.querySelector('.splide__pagination');
       arrowsContainer.setAttribute('aria-hidden', 'true');
       paginationContainer.setAttribute('aria-hidden', 'true');
     })

     splide.on('pagination:updated', (data) => {
       data.items.forEach((item, index) => {
         let perPage = 1;
         let start = index * perPage + 1;
         let end = Math.min(start + perPage - 1, splide.length);

         item.button.ariaLabel = `Afficher les avis clients ${start} à ${end}`;
         item.button.ariaCurrent = item.button.ariaSelected;
       });
     });

          const handleSplideCarouselForTables = () => {
         if(isMobile.matches && !isMounted) {
           $('.sr-only_container').removeClass('hide-table').addClass('sr-only');
           splide.mount();
           isMounted = true;
         } else if (!isMobile.matches && isMounted) {
           $('.sr-only_container').addClass('hide-table').removeClass('sr-only');
           splide.destroy();
           isMounted = false;
         }
     }

     isMobile.addEventListener('change', handleSplideCarouselForTables);
     handleSplideCarouselForTables();  
   })
})()