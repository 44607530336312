

(function carouselCard () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "carousel-card";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    $(() => {
      const configDesktop = 3;
      const configTablet = 2;
      const configMobile = 1;

      const splide = new Splide('.splide.carousel-card__slider', {
        perPage: configDesktop,
        perMove: configDesktop,
        gap: '1.5rem',
        easing: 'ease-out',
        omitEnd: true,
        breakpoints: {
          1024: {
            perPage: configTablet,
            perMove: configTablet,
            arrows: false
          },
          768: {
            perPage: configMobile,
            perMove: configMobile,
            arrows: false,
            gap: '1rem'
          }
        },
        i18n: {
          prev: "Slide précédente",
          next: "Slide suivante",
          slideX: "Aller à la slide %s",
          carousel: "carrousel",
          slide: "slide",
          select: "Sélectionner une slide à afficher",
          slideLabel: "%s sur %s"
        }
      });

      splide.on('pagination:updated', (data) => {
        data.items.forEach((item, index) => {
          let perPage = window.innerWidth >= 1024 ? configDesktop : window.innerWidth >= 560 ? configTablet : configMobile;
          let start = index * perPage + 1;
          let end = Math.min(start + perPage - 1, splide.length);

          item.button.ariaLabel = `Afficher les avis clients ${start} à ${end}`;
          item.button.ariaCurrent = item.button.ariaSelected;
        });
      });

            splide.mount();
    })
  })()