

(function searchBar () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "search-bar";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    $(async () => {
      const searchBar = document.querySelector("#search-bar");
      const clearBtn = document.querySelector(".search-bar__icon-cross");

      searchBar.addEventListener("input", () => {
        if (searchBar.value.length > 0) {
          clearBtn.style.display = "block";
        } else if (searchBar.value.length <= 0) {
          clearBtn.style.display = "none";
        }
      });

      clearBtn.addEventListener("click", () => { 
        searchBar.value = "";
        clearBtn.style.display = "none";
      })

      const inbentaApi = GlobalSite.checkDependency("GlobalSite.inbentaApi");
      const searchEngineUi = GlobalSite.checkDependency("GlobalSite.searchEngineUi");
      const getUrlParam = GlobalSite.checkDependency("GlobalSite.getUrlParam");

      const searchInput = $(".search-bar .search");
      const submitInput = $(".search-bar .submit");
      const deleteButton = $(".search-bar .delete-btn");
      const autocomplete = $(".inbenta-autocomplete");
      const param = getUrlParam("q") || getUrlParam("search");

      const inbentaAuth = await inbentaApi.auth();

      inbentaAuth && searchEngineUi([searchInput, submitInput, deleteButton, autocomplete], "moteur", param);

      if (param) {
        searchInput.val(param);
      }

      const listItems = document.querySelector(".inbenta-faq-list-items");
      const results = document.querySelector(".search-bar__results");
      const showMore = document.querySelector(".search-bar__results-more");
      const showMoreBtn = document.querySelector(".search-bar__results-more-btn");

      const observer = new MutationObserver(() => {
        const items = document.querySelectorAll(".inbenta-faq-list-items .inbenta-faq-list-item");

                if (items.length > 0) {
          results.classList.remove("hide");
        } else {
          results.classList.add("hide");
        }

        if (items.length > 5) {
          showMore.classList.remove("hide");
        } else {
          showMore.classList.add("hide");
        }
      });

      observer.observe(listItems, {
        subtree: true,
        childList: true,
        attributes: true,
      });

      showMoreBtn.addEventListener("click", () => {
        const items = document.querySelectorAll(".inbenta-faq-list-items .inbenta-faq-list-item");
        const isOpenShowMore = !Array.from(items).some(({classList}) => classList.contains("inbenta-faq-list-item--hidden"));

        if (items.length > 5 && !isOpenShowMore) {
          items.forEach(item => item.classList.remove("inbenta-faq-list-item--hidden"));
          showMoreBtn.innerHTML = "Afficher moins de résultats";
        } else if (isOpenShowMore) {
          Array.from(items).slice(5, items.length).forEach(item => item.classList.add("inbenta-faq-list-item--hidden"));
          showMoreBtn.innerHTML= "Afficher tous les résultats";
        }
      })
    })

    })()