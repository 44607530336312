(function headerMenu() {
  const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
  const compName = "header-menu";
  const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }

  const burgerOpenIcon = document.getElementById('burger-open');
  const burgerCloseIcon = document.getElementById('burger-close');
  const menuMobileContainer = document.querySelector('.menu-items-container');
  const secondaryMenu = document.querySelector('.secondary-menu');

  burgerOpenIcon.addEventListener('click', function () {
    menuMobileContainer.style.display = 'block';
    moveSecondaryMenuOnMobile();
    setTimeout(() => {
      menuMobileContainer.style.transform = 'translateY(0)';
    }, 200);
    document.body.style.overflow = "hidden"; 
  });

  burgerCloseIcon.addEventListener('click', function () {
    menuMobileContainer.style.transform = 'translateY(100%)';
    setTimeout(() => {
      menuMobileContainer.style.display = 'none';
      if (secondaryMenu) {
        secondaryMenu.style.display = 'none';
      }
    }, 500);
    document.body.style.overflow = "";
  });

  const menuItems = document.querySelectorAll('.menu-item');
  const isMobile = window.matchMedia('(max-width: 1024px)').matches;

  const showSubmenu = (menuItem) => {
    const submenu = menuItem.querySelector('.submenu');
    const arrowIcon = menuItem.querySelector('.arrow');
    if (submenu) {
      submenu.style.display = 'flex';
      if (!isMobile) {
        submenu.style.opacity = 1;
      }
      setTimeout(() => {
        if (isMobile) {
          submenu.style.opacity = 1;
        }
      }, 200);
      if (isMobile && arrowIcon) {
        arrowIcon.style.transform = 'rotate(180deg)';
      }
    }
  };

  const hideSubmenu = (menuItem) => {
    const submenu = menuItem.querySelector('.submenu');
    const arrowIcon = menuItem.querySelector('.arrow');
    if (submenu) {
      submenu.style.opacity = 0;
      submenu.style.display = 'none';
      if (isMobile) {
          setTimeout(() => {
          submenu.style.transform = 'translateY(0)';
        }, 500);
      } if (arrowIcon) {
        arrowIcon.style.transform = 'rotate(0deg)';
      }
    }
  };

  let hideTimout; 

  const handleMenuItemEvent = (event) => {
    const menuItem = event.currentTarget;
    const submenu = menuItem.querySelector('.submenu');
    const isWideScreen = window.matchMedia('(min-width: 1025px)').matches;

    if ((['mouseenter', 'focusin'].includes(event.type)) && isWideScreen) {
      menuItems.forEach((otherMenuItem) => {
        if (otherMenuItem !== menuItem) {
          clearTimeout(hideTimout);
          hideSubmenu(otherMenuItem);
          otherMenuItem.classList.remove('menu-item-active');
        }
      });

      clearTimeout(hideTimout);

      showSubmenu(menuItem);
      menuItem.classList.add('menu-item-active');

    } else if ((['mouseleave', 'focusout'].includes(event.type)) && isWideScreen) {
      hideTimout = setTimeout(() => {
        if (!menuItem.contains(document.activeElement)) {
          hideSubmenu(menuItem);
          menuItem.classList.remove('menu-item-active');
        }
      }, 500);

    } else if ((event.type === 'click') && !isWideScreen) {
      menuItems.forEach((otherMenuItem) => {
        if (otherMenuItem !== menuItem) {
          clearTimeout(hideTimout);
          hideSubmenu(otherMenuItem);
        }
      });

      if (submenu && ((submenu.style.display === 'none') || (submenu.style.display === ''))) {
        showSubmenu(menuItem);
        menuItem.classList.add('menu-item-active');
      } else {
        hideSubmenu(menuItem);
        menuItem.classList.remove('menu-item-active');
      }
    }
  };

  const handleMenus = () => {
    menuItems.forEach((menuItem) => {
      const submenu = menuItem.querySelector('.submenu');
      let isMouseOverMenuItem = false; 

        ['mouseenter', 'mouseleave', 'focusin', 'focusout', 'click'].forEach((eventType) => {
        menuItem.addEventListener(eventType, handleMenuItemEvent);
      });

      if (submenu) {
        submenu.addEventListener('mouseenter', () => {
          clearTimeout(hideTimout);
        });
        submenu.addEventListener('mouseleave', (event) => {
          hideTimout = setTimeout(() => {
            if (!menuItem.contains(document.activeElement) && !isMouseOverMenuItem) {
              hideSubmenu(menuItem);
            }
          }, 500);
        });

          menuItem.addEventListener('mouseover', () => {
          isMouseOverMenuItem = true;
          clearTimeout(hideTimout);
        });

          menuItem.addEventListener('mouseout', () => {
          isMouseOverMenuItem = false;
        })
      }
    });
  }

  const moveSecondaryMenuOnMobile = () => {
    const menuItemsContainer = document.querySelector('.menu-items-container')

      if (isMobile) {
      menuItemsContainer.appendChild(secondaryMenu);
      secondaryMenu.style.display = 'flex';
    }
  }

  const turnGhostButtonsToSecondary = () => {
    const buttons = document.querySelectorAll('.secondary-submenu-item--button');
    buttons.forEach(button => {
        if (button.classList.contains('ghost')) {
            if (isMobile) {
                button.classList.remove('ghost');
                button.classList.add('secondary');
            } else {
                button.classList.remove('secondary');
                button.classList.add('ghost');
            }
        }
    });
  }

  const setScrollBarWith = () => {
    const getScrollBarWith = () => {
      let el = document.createElement('div');
      el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
      document.body.appendChild(el);
      let width = el.offsetWidth - el.clientWidth;
      document.documentElement.style.setProperty('--scrollbar-width', `${width > 0 ? width : 0}px`);
      el.remove();
    };

      getScrollBarWith();
  }

  const onResize = () => {
    handleMenus();
    moveSecondaryMenuOnMobile();
    turnGhostButtonsToSecondary();
    setScrollBarWith();
  }

    const onDomContentLoaded = () => {
    handleMenus()
    moveSecondaryMenuOnMobile();
    turnGhostButtonsToSecondary();
    setScrollBarWith();
  }

  window.addEventListener('resize', onResize)
  window.addEventListener('DOMContentLoaded', onDomContentLoaded);

  $(() => {
  })

})()
