

(function footerMain () {
    const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
    const compName = "footer-secondary";
    const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
    const Log = logFactory(`${compName}.js`);

      if (!isCompExists(compName, Log)) {
       return;
     }

      $(() => {
        const $cookiePreferences = $('#cookies-pref').find('a');
        $cookiePreferences.on('click tap', (e) => {
            e.preventDefault();
            $('.ot-sdk-show-settings').trigger('click');
        });
     })

      })()