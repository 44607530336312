

(function anchors () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "anchors";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    $(() => {
    })

    })()