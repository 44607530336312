

(function widgetSimu() {
  const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
  const compName = "widget-simu";
  const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }

  $(() => {
    let amountWidth;
    let maxAmount = 999999;
    let minAmount = 500;
    const euroSymbol = document.getElementById('input-symbol');
    const inputField = document.getElementById('input_field-input');
    const btnSimulate = document.querySelector('#widget-simu-button');
    let isValid = false;

    const clearButton = document.getElementById('clear-button');
    const validCircleIcon = document.querySelector('.i-check-circle-fill');
    const errorIcon = document.getElementById('error-icon');

    const errorMessageMin = document.querySelector('.input-block__message--min');
    const errorMessageMax = document.querySelector('.input-block__message--max');

    const calculateTextLength = (content, fontFamily, fontSize) => {
      const tempElement = document.createElement('span');
      tempElement.style.fontFamily = fontFamily;
      tempElement.style.fontSize = fontSize;
      tempElement.style.visibility = 'hidden';
      tempElement.style.whiteSpace = 'pre';
      tempElement.textContent = content;

      document.body.appendChild(tempElement);
      const textWidth = tempElement.getBoundingClientRect().width;
      document.body.removeChild(tempElement);

      return placeholderWidth = textWidth;
    }

    const formatValue = (value) => {
      if (value.trim() === '') {
        return ''; 
      }
      let numericValue = parseFloat(value.replace(/\D/g, '')); 
      return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") 
    }

    const formatAndStickInputSymbolToAmount = () => {
      const computedStyles = getComputedStyle(inputField);
      const fontSize = computedStyles.getPropertyValue('font-size');
      const fontFamily = computedStyles.getPropertyValue('font-family');

      let formattedValue = formatValue(inputField.value);

      if (formattedValue) {
        inputField.value = formattedValue;

        let textWidth = calculateTextLength(formattedValue, fontFamily, fontSize);
        amountWidth = (textWidth + 22) + 'px';

        clearButton.style.display = 'block';

        let numericValue = formattedValue ? parseFloat(formattedValue.replace(/\D/g, '')) : 0;

        if (numericValue >= minAmount && numericValue <= maxAmount) {
          isValid = true;

          validCircleIcon.style.display = 'block';
          errorIcon.style.display = 'none';

          inputField.classList.add('is-valid');
          inputField.classList.remove('error');

          errorMessageMin.style.display = 'none';
          errorMessageMax.style.display = 'none';

          inputField.setAttribute('aria-invalid', 'false');

          inputField.removeAttribute('aria-describedby');
        } else {
          isValid = false;

          errorIcon.style.display = 'block';
          validCircleIcon.style.display = 'none';

          inputField.classList.add('error');
          inputField.classList.remove('is-valid');

          inputField.setAttribute('aria-invalid', 'true');

          if (numericValue < minAmount) {
            errorMessageMin.style.display = 'block';
            errorMessageMax.style.display = 'none';

            inputField.setAttribute('aria-describedby', 'error-message-min')

          } else if (numericValue > maxAmount) {
            errorMessageMin.style.display = 'none';
            errorMessageMax.style.display = 'block';

            inputField.setAttribute('aria-describedby', 'error-message-max')
          }
        }
      } else {
        let placeholderWidth = calculateTextLength(inputField.placeholder, fontFamily, fontSize);
        amountWidth = (placeholderWidth + 22) + 'px';

        clearButton.style.display = 'none';
        validCircleIcon.style.display = 'none';
        errorIcon.style.display = 'none';

        inputField.classList.remove('error');
        inputField.classList.remove('is-valid');

        inputField.removeAttribute('aria-invalid');
        inputField.removeAttribute('aria-describedby');
      }

      euroSymbol.style.left = amountWidth;
    }

    const clearAmount = () => {
      inputField.value = '';
      euroSymbol.style.left = '2rem';
      clearButton.style.display = 'none';
      validCircleIcon.style.display = 'none';
      errorIcon.style.display = 'none';
      errorMessageMin.style.display = 'none';
      errorMessageMax.style.display = 'none';

      inputField.classList.remove('error');
      inputField.classList.remove('is-valid');

      inputField.setAttribute('aria-invalid', 'false');
    }

    inputField.addEventListener('input', formatAndStickInputSymbolToAmount);
    clearButton.addEventListener('click', clearAmount);

    inputField.addEventListener('keypress', function(e) {
      if ((e.which < 48 || e.which > 57)
          && (e.key < "0" || e.key > "9"))
        {
        e.preventDefault();
      }
    })

    btnSimulate.addEventListener('click', (e) => {
      e.preventDefault();

      const checkHasQueryPams = (url) => new URL(url).searchParams.size > 0 || url.indexOf('?') !== -1;

      const btnSimulateHref = btnSimulate.href;
      const simulateValue = formatValue(inputField.value).replace(/\D/g, '');
      const redirectTo = `${btnSimulateHref}${isValid ? `${checkHasQueryPams(btnSimulateHref) ? '&' : '?'}goodAmount=${simulateValue}` : ''}`;

      window.location.replace(redirectTo);
    })
  })

})()