(function goToTopButton () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "go-to-top-button";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    const goToTopButton = document.querySelector('#go-to-top-button');

    let ua = navigator.userAgent.toLocaleLowerCase();
    let isSamsungBrowser = ua.includes("samsungbrowser");

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

          const onScroll = () => {
        if (window.scrollY === 0) {
          const firstFocusableElement = document.body.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
          if (firstFocusableElement) {
            firstFocusableElement.focus();
          }
          window.removeEventListener('scroll', onScroll);
        }
      };

          window.addEventListener('scroll', onScroll);
    }

    const debounce = (func, delay) => {
      let debounceTimer;
      return function() {
        const context = this; 
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      }
    }

        const handleGoToTopButtonDisplay = () => {
      let isMobile = window.innerWidth <= 830;
      let isButtonDisplayed = window.scrollY > 600;

      if (!isMobile || !isButtonDisplayed || isSamsungBrowser) {
        goToTopButton.style.opacity = '0';
        setTimeout(() => {
          goToTopButton.style.display ='none';
        }, 100)
      } else if (isButtonDisplayed) {
        goToTopButton.style.display ='flex';
        setTimeout(() => {
          goToTopButton.style.opacity = '1';
        }, 100)
      }
    }

    const checkIfStickyButtonExists = () => {
      const stickySimuBtn = document.querySelector('.sticky-button');
      if (stickySimuBtn) {
        const stickySimuBtnHeight = stickySimuBtn.offsetHeight;
        goToTopButton.style.bottom = `${stickySimuBtnHeight + 16}px`;
      } else {
        goToTopButton.style.bottom = '1rem';
      }
    }

    goToTopButton.addEventListener('click', scrollToTop);

    window.addEventListener('resize', debounce(handleGoToTopButtonDisplay, 200));
    window.addEventListener('scroll', debounce(handleGoToTopButtonDisplay, 200));

    $(() => {
      handleGoToTopButtonDisplay();
      checkIfStickyButtonExists();
    })

    })()