(function avisVerifies() {
  const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
  const compName = "avis-verifies";
  const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }

  $(async () => {
    const configDesktop = 3;
    const configTablet = 2;
    const configMobile = 1;

    const splide = new Splide('.splide.avisverifies__container', {
      perPage: configDesktop,
      perMove: configDesktop,
      arrows: false,
      gap: '1.5rem',
      easing: 'ease-out',
      omitEnd: true,
      breakpoints: {
        1024: {
          perPage: configTablet,
          perMove: configTablet,
          arrows: false
        },
        768: {
          perPage: configMobile,
          perMove: configMobile,
          arrows: false,
          gap: '1rem'
        }
      },
      i18n: {
        prev: "Slide précédente",
        next: "Slide suivante",
        slideX: "Aller à la slide %s",
        carousel: "carrousel",
        slide: "slide",
        select: "Sélectionner une slide à afficher",
        slideLabel: "%s sur %s"
      }
    });

    splide.on('pagination:updated', (data) => {
      data.items.forEach((item, index) => {
        let perPage = window.innerWidth >= 1024 ? configDesktop : window.innerWidth >= 560 ? configTablet : configMobile;
        let start = index * perPage + 1;
        let end = Math.min(start + perPage - 1, splide.length);

        item.button.ariaLabel = `Afficher les avis clients ${start} à ${end}`;
        item.button.ariaCurrent = item.button.ariaSelected;
      });
    });

    const getCustomReviews = GlobalSite.checkDependency('GlobalSite.getCustomReviews');
    const getGlobalRateAndReview = GlobalSite.checkDependency('GlobalSite.getGlobalRateAndReview');

    const fomatDate = GlobalSite.checkDependency('GlobalSite.fomatDate');
    const formatName = (name) => name.toLowerCase().charAt(0).toUpperCase() + name.slice(1).toLowerCase();

    const gradeValueBlock = document.querySelector('.avisverifies #grade-value > span');
    const reviewsListBlock = document.querySelector('.avisverifies #reviews-list');

    const customReviews = await getCustomReviews();
    const { globalRate } = await getGlobalRateAndReview();

    gradeValueBlock.textContent = Number(globalRate).toPrecision(2).replace('.', ',');

    customReviews.map((review) => {
      const htmlReview = `
        <li class="splide__slide">
          <div class="splide__slide_container">
            <p class="splide__slide_container-comment">
              ${review.review}
            </p>
            <div class="splide__slide_container-infos">
              <div>
                <cite class="splide__slide_container-infos_author">
                  ${formatName(review.firstname)} ${review.lastname}.
                </cite>
                <p class="splide__slide_container-infos_date">
                  ${fomatDate(review.review_date)}
                </p>
              </div>
            </div>
          </div>
        </li>
      `

      reviewsListBlock.insertAdjacentHTML('afterbegin', htmlReview);
    })

    const generateStars = (percentage) => {
      const container = document.querySelector("#rating-stars");

      if (container) {
        container.innerHTML = ''; 

          const totalStars = 5; 
        const fullStars = Math.floor((percentage > 100 ? 100 : percentage) / 20); 
        const remainderPercentage = ((percentage > 100 ? 100 : percentage) % 20) * 5; 

          const starSVG = `
          <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.45149 12.4773L0.562343 9.30433C-0.473864 8.62893 0.0150516 7.06706 1.27017 7.06706H7.23931C7.78661 7.06706 8.26822 6.72936 8.44335 6.22985L10.3114 0.833695C10.6982 -0.277898 12.3328 -0.277898 12.7122 0.833695L14.5803 6.22985C14.7555 6.72936 15.2371 7.06706 15.7844 7.06706H21.7316C22.9867 7.06706 23.4756 8.63596 22.4321 9.30433L17.5138 12.4773C17.0395 12.7798 16.8352 13.3567 17.0176 13.8773L18.9222 19.3861C19.3162 20.5188 17.9589 21.4685 16.9592 20.7509L12.2379 17.3599C11.7855 17.0362 11.1725 17.0362 10.7201 17.3599L5.99879 20.7509C5.00636 21.4685 3.64908 20.5188 4.03583 19.3861L5.94041 13.8773C6.12284 13.3567 5.91851 12.7868 5.44419 12.4773" fill="#F6A622"/>
          </svg>
        `;

        for (let i = 0; i < fullStars; i++) {
          container.innerHTML += `
            <div class="star full">
              ${starSVG}
            </div>
          `;
        }

        if (fullStars < totalStars) {
          container.innerHTML += `
            <div class="star partial" style="--star-remainder-percentage: ${remainderPercentage}%;">
              ${starSVG}
            </div>
          `;
        }
      }
    }

    const percentageRate = (globalRate / 5) * 100
    generateStars(percentageRate); 

    splide.mount();
  })
})()